import React, { useCallback, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, Modal } from 'reactstrap';
import { UpdateUnitsPresalesStateForm } from '../models/investors';
import useAuth from '../../../hooks/useAuth';
import useCallApiAndLoad from '../../../hooks/useCallApiAndLoad';
import { fetchResponseAdapter } from '../../../adapters/fetchAdapter';
import { getLandlordByMailEndpoint } from '../../../services/landlordService';

export interface Props {
  // entity: any;
  model_id: string;
  unit_id: string;
  model_name?: string;
  unit_name?: string;
  isOpen: boolean;
  marketplace_type?: 'rent' | 'sale';
  onToggle: () => void;
  // onConfirmation: (entity: any, reason: string, comments: string) => void;
  onConfirmation: (
    model_id: string,
    unit_id: string,
    form: UpdateUnitsPresalesStateForm,
  ) => void;
  warningText: string;
  confirmationText: string;
  abortText: string;
}
const TakeDownUnitModal: React.FC<Props> = ({
  // entity,
  model_id,
  unit_id,
  isOpen,
  marketplace_type = 'rent',
  model_name,
  unit_name,
  onToggle,
  onConfirmation,
  warningText,
  confirmationText,
  abortText,
}) => {
  const { getAuthToken } = useAuth();
  const token = getAuthToken();
  const { callEndpoint } = useCallApiAndLoad();

  const [landlordEmail, setLandlordEmail] = useState<string>('');
  const [landlord, setLandlord] = useState<any>();
  const [takeDownReason, setTakeDownReason] = useState<string>('');
  const [takeDownAdditionalComments, setTakeDownAdditionalComments] =
    useState<string>('');
  const isValid = () =>
    takeDownReason !== '' && takeDownAdditionalComments !== '';

  const [errorAlert, setErrorAlert] = useState<any>(null);
  const takeDownOptions = [
    {
      value: 'disabled',
      label: 'Deshabilitar',
    },
    {
      value: 'rented_external',
      label: 'Rentada fuera de la plataforma',
    },
  ];

  const takeDownSalesOptions = [
    {
      value: 'disabled',
      label: 'Deshabilitar',
    },
    {
      value: 'sold_external',
      label: 'Vendida fuera de la plataforma',
    },
  ];

  const handleSearchLandlord = useCallback(
    async (landlordEmailForSearch: string) => {
      if (!landlordEmailForSearch) return;

      const { status, data } = await callEndpoint(
        getLandlordByMailEndpoint(landlordEmailForSearch, token),
      );
      if (status === 200) {
        const landlordResponse = fetchResponseAdapter(data);
        setLandlord(landlordResponse.data);
      } else {
        setLandlord(null);
        setErrorAlert('El inversionista no fue encontrado.');
      }
    },
    [callEndpoint],
  );

  const displayOptions = () =>
    marketplace_type === 'sale' ? takeDownSalesOptions : takeDownOptions;

  const confirmationModal = (
    <Modal
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
      isOpen={isOpen}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-notification">
          Tu atención es requerida
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-3x" />
          <h4 className="heading mt-4">
            Dar de baja a {unit_name} del modelo {model_name}{' '}
          </h4>
          <p>Completa la razón por la cual estás dando de baja está unidad</p>
          <Input
            type="select"
            onChange={(event) => {
              setTakeDownReason(event.target.value);
              setLandlord(null);
              setErrorAlert('');
            }}
          >
            <option value="">Selecciona una razón</option>
            {displayOptions().map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </Input>
          <p className="mt-3">Comentarios adicionales</p>
          <Input
            type="textarea"
            value={takeDownAdditionalComments}
            onChange={(event) =>
              setTakeDownAdditionalComments(event.target.value)
            }
          />
          {marketplace_type === 'sale' &&
            takeDownReason === 'sold_external' && (
              <>
                <p className="mt-3">Asignar propietario (opcional)</p>
                <InputGroup className="mb-3">
                  <Input
                    onChange={({ target }) =>
                      setLandlordEmail(target.value || '')
                    }
                    placeholder="Correo de propietario"
                    type="text"
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      onClick={() => handleSearchLandlord(landlordEmail)}
                      color="primary"
                    >
                      {' '}
                      Buscar
                    </Button>
                    <Button
                      onClick={() => {
                        setErrorAlert('');
                        setLandlord(null);
                        setLandlordEmail('');
                      }}
                      color="default"
                    >
                      {' '}
                      Limpiar
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
                {landlord && (
                  <h5>
                    Asignará la unidad a {landlord.name} | Teléfono:{' '}
                    {landlord.phone}
                  </h5>
                )}
                {errorAlert !== '' && !landlord && <h5>{errorAlert}</h5>}
              </>
            )}
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="btn-white"
          color="default"
          type="button"
          disabled={!isValid()}
          onClick={() =>
            onConfirmation(model_id, unit_id, {
              status: takeDownReason,
              comment: takeDownAdditionalComments,
              landlord_id: landlord?.landlord_id ?? null,
            })
          }
        >
          {confirmationText}
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          {abortText}
        </Button>
      </div>
    </Modal>
  );

  return <>{confirmationModal}</>;
};

export default TakeDownUnitModal;
