import {
  GoogleMap,
  Marker,
  Polygon,
  OverlayView,
} from '@react-google-maps/api';
import { MAP_STYLE } from '../../constants/MapConstants';
import AdminPage from '../../components/admin/AdminPage';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { Controller } from 'react-hook-form';
import usePolygons from './hooks/usePolygons';
import LoadingCard from '../../components/LoadingCard';
import Select from 'react-select';
import { listToSelectOptionAdapter } from '../../adapters/listAdapter';
import {
  PROPERTY_TYPES_LIST,
  POLYGONS_TYPES_LIST,
} from '../properties/constants/propertyConstants';
import { Option } from '../../models/formModel';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Coordinate } from '../../models/mapViewModel';
import { useState } from 'react';
import classnames from 'classnames';

export type Props = {
  onChange?: Function;
  defaultCenter?: Coordinate;
};

const AdminPolygons = ({ onChange, defaultCenter }: Props) => {
  const name = 'Configuración propi estimate';
  const parentName = 'Propi Estimate';
  const {
    // level for preview polygons
    pickedLevel,
    setPickedLevel,
    polygonsByLevel,
    // form
    control,
    errors,
    handleSubmit,
    setValue,
    doFormAction,
    // Polygons map components
    isLoaded,
    onLoad,
    mapState,
    handleMapClick,
    polygonMarkerCoords,
    builtPolygon,
    handleBuildPolygon,
    handleDiscardPolygonChanges,
    defaultMapCenter,
    defaultZoom,
    clearMap,
    polygonErrorMsg,
    // Sweet alert
    sweetAlert,
    alertProps,
    // update polygon refs and state
    updatePolygonRef,
    updatePolygon,
    loadUpdatePolygonData,
    handleUpdatePolygon,
    // parent polygons to assign
    polygonsParent,
    loadingParentPolygons,
  } = usePolygons();

  const projectTypeOptions = listToSelectOptionAdapter(
    PROPERTY_TYPES_LIST,
    'value',
    'display_value',
  );

  const polygonsTypeOptions = listToSelectOptionAdapter(
    POLYGONS_TYPES_LIST,
    'value',
    'display_value',
  );

  const levelOptions: Option[] = [
    {
      label: 'Nivel 2',
      value: '2',
    },
    {
      label: 'Nivel 3',
      value: '3',
    },
    {
      label: 'Nivel 4',
      value: '4',
    },
    {
      label: 'Nivel 5',
      value: '5',
    },
  ];

  // States used for OverlayView info on each polygon
  const [hoveredPolygon, setHoveredPolygon] = useState<any | null>(null);
  const [tooltipPosition, setTooltipPosition] =
    useState<google.maps.LatLng | null>(null);

  const handlePolygonMouseOver = (
    polygon: any,
    event: google.maps.MapMouseEvent,
  ) => {
    console.log(event.latLng);
    setHoveredPolygon(polygon);
    setTooltipPosition(event.latLng || null); // Get the position of the mouse cursor
  };

  const handlePolygonMouseOut = () => {
    setHoveredPolygon(null);
    setTooltipPosition(null);
  };

  if (!isLoaded) {
    return <LoadingCard name={name} parent_name={parentName} />;
  }

  return (
    <AdminPage name={name} parentName={parentName}>
      {sweetAlert && (
        <SweetAlert {...alertProps}>{alertProps.text || ''}</SweetAlert>
      )}
      <Card>
        <CardBody>
          <Row>
            {/* FORM */}
            <Col md="3">
              <Form role="form" onSubmit={handleSubmit(doFormAction)}>
                <CardBody>
                  {/* Square identifier */}
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <label className="form-control-label">
                          Nombre identificador
                        </label>
                        <Controller
                          name="square_identifier"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.square_identifier?.message &&
                                'is-invalid'
                              }
                              {...field}
                              id="square_identifier"
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.square_identifier?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Level */}
                  <FormGroup>
                    <label className="form-control-label" htmlFor="quotas">
                      Nivel de polígono
                    </label>
                    <Controller
                      name="level"
                      control={control}
                      render={({ field: { ref } }) => (
                        <Select
                          defaultValue={levelOptions[0]}
                          placeholder="Selecciona..."
                          options={levelOptions}
                          onChange={(selected: any) => {
                            const { value = '' } = selected;
                            setPickedLevel(value);
                            setValue('level', value);
                          }}
                          ref={ref}
                        />
                      )}
                    />
                    <h5 className="text-danger">{errors.level?.message}</h5>
                  </FormGroup>
                  {/* Parent Id OPTIONAL */}
                  <FormGroup>
                    <label className="form-control-label" htmlFor="parent_id">
                      Asignar polígono padre
                    </label>
                    {!loadingParentPolygons && (
                      <Controller
                        name="parent_id"
                        control={control}
                        render={({ field: { ref } }) => (
                          <Select
                            placeholder="Selecciona..."
                            options={polygonsParent}
                            onChange={(selected: any) => {
                              const { value = '' } = selected;
                              setValue('parent_id', value);
                            }}
                            ref={ref}
                          />
                        )}
                      />
                    )}
                    <h5 className="text-danger">{errors.parent_id?.message}</h5>
                  </FormGroup>
                  {/* Property type */}
                  <FormGroup>
                    <label className="form-control-label" htmlFor="quotas">
                      Tipo de propiedad/proyecto
                    </label>
                    <Controller
                      name="property_type"
                      control={control}
                      render={({ field: { ref } }) => (
                        <Select
                          placeholder="Selecciona..."
                          options={projectTypeOptions}
                          onChange={(selected: any) => {
                            const { value = '' } = selected;
                            setValue('property_type', value);
                          }}
                          ref={ref}
                        />
                      )}
                    />
                    <h5 className="text-danger">
                      {errors.property_type?.message}
                    </h5>
                  </FormGroup>
                  {/* Polygon type */}
                  <FormGroup>
                    <label className="form-control-label" htmlFor="quotas">
                      Tipo de polígono
                    </label>
                    <Controller
                      name="polygon_type"
                      control={control}
                      render={({ field: { ref } }) => (
                        <Select
                          placeholder="Selecciona..."
                          options={polygonsTypeOptions}
                          onChange={(selected: any) => {
                            const { value = '' } = selected;
                            setValue('polygon_type', value);
                          }}
                          ref={ref}
                        />
                      )}
                    />
                    <h5 className="text-danger">
                      {errors.polygon_type?.message}
                    </h5>
                  </FormGroup>
                  {/* Area and square rods */}
                  <FormGroup>
                    <Row>
                      <Col md={12} sm={12}>
                        <label className="form-control-label">
                          Area de construcción
                        </label>
                        <Controller
                          name="area"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={errors.area?.message && 'is-invalid'}
                              {...field}
                              id="area"
                              type="number"
                              step="0.01"
                              min={0}
                            />
                          )}
                        />
                        <h5 className="text-danger">{errors.area?.message}</h5>
                      </Col>
                      <Col md={12} sm={12}>
                        <label className="form-control-label">
                          Area de terreno
                        </label>
                        <Controller
                          name="square_rods"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.square_rods?.message && 'is-invalid'
                              }
                              {...field}
                              id="square_rods"
                              type="number"
                              step="0.01"
                              min={0}
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.square_rods?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Min price */}
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <label className="form-control-label">
                          Precio mínimo de polígono
                        </label>
                        <Controller
                          name="min_price_value"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.min_price_value?.message && 'is-invalid'
                              }
                              {...field}
                              id="min_price_value"
                              type="number"
                              step="0.01"
                              min={0}
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.min_price_value?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Mid price */}
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <label className="form-control-label">
                          Precio medio de polígono
                        </label>
                        <Controller
                          name="mid_price_value"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.mid_price_value?.message && 'is-invalid'
                              }
                              {...field}
                              id="mid_price_value"
                              type="number"
                              step="0.01"
                              min={0}
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.mid_price_value?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Max price */}
                  <FormGroup>
                    <Row>
                      <Col md={12}>
                        <label className="form-control-label">
                          Precio máximo de polígono
                        </label>
                        <Controller
                          name="high_price_value"
                          control={control}
                          render={({ field }) => (
                            <Input
                              className={
                                errors.high_price_value?.message && 'is-invalid'
                              }
                              {...field}
                              id="high_price_value"
                              type="number"
                              step="0.01"
                              min={0}
                            />
                          )}
                        />
                        <h5 className="text-danger">
                          {errors.high_price_value?.message}
                        </h5>
                      </Col>
                    </Row>
                  </FormGroup>
                </CardBody>
                <CardFooter>
                  <Button color={'success'} disabled={false}>
                    {updatePolygon && updatePolygon.polygon_id
                      ? 'Actualizar'
                      : 'Crear'}{' '}
                    polígono
                  </Button>
                </CardFooter>
              </Form>
            </Col>
            {/* MAP */}
            <Col md="9">
              <CardBody>
                <h5 className="text-danger">{polygonErrorMsg}</h5>
                <h5>Nivel mostrado: {pickedLevel}</h5>
                <Row>
                  <Col>
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={{
                          width: '100%',
                          height: '600px',
                        }}
                        center={defaultMapCenter}
                        zoom={defaultZoom}
                        onLoad={onLoad}
                        onZoomChanged={() =>
                          mapState && setValue('zoom', mapState.getZoom())
                        }
                        onCenterChanged={() =>
                          mapState &&
                          setValue(
                            'center',
                            JSON.stringify(mapState.getCenter()),
                          )
                        }
                        options={{
                          draggableCursor: 'crosshair',
                          minZoom: 9,
                          styles: MAP_STYLE,
                        }}
                        onClick={(e) => handleMapClick(e)}
                      >
                        {/* Polygons by level */}
                        {polygonsByLevel.length > 0 &&
                          polygonsByLevel.map((currentPolygon) => {
                            const { id, polygon } = currentPolygon;
                            // check if we have polygon update state
                            const polygonIdentifier = `polygon-${id}`;
                            const isEditable = id === updatePolygon.polygon_id;
                            return (
                              <Polygon
                                key={polygonIdentifier}
                                editable={isEditable}
                                draggable={isEditable}
                                ref={isEditable ? updatePolygonRef : null}
                                onMouseUp={handleUpdatePolygon}
                                options={{
                                  fillColor: isEditable ? '#333333' : 'green',
                                  strokeColor: isEditable
                                    ? '#000000'
                                    : '#2FB672',
                                  clickable: true,
                                }}
                                onClick={() =>
                                  pickedLevel >= 4 &&
                                  loadUpdatePolygonData(id, polygon)
                                }
                                path={polygon}
                                onMouseOver={(event) =>
                                  handlePolygonMouseOver(
                                    {
                                      id: currentPolygon.id,
                                      name: currentPolygon.name,
                                    },
                                    event,
                                  )
                                }
                                onMouseOut={handlePolygonMouseOut}
                              />
                            );
                          })}

                        {/* Polygon built */}
                        {!polygonMarkerCoords.length &&
                          builtPolygon.length > 0 && (
                            <Polygon
                              editable
                              key={`build-polygon`}
                              options={{
                                fillColor: '#333333',
                                strokeColor: '#000000',
                              }}
                              path={builtPolygon}
                            />
                          )}
                        {/*  Markers rto build Polygon */}
                        {polygonMarkerCoords.map((coord, index) => (
                          <Marker
                            key={index}
                            position={{ lat: coord.lat, lng: coord.lng }}
                            label={String(index + 1)}
                          />
                        ))}

                        {/* Overlay place */}
                        {hoveredPolygon && tooltipPosition && (
                          <OverlayView
                            position={tooltipPosition}
                            mapPaneName={OverlayView.FLOAT_PANE}
                          >
                            <div
                              style={{
                                width: '150px',
                                backgroundColor: 'white',
                                padding: '5px',
                                border: '1px solid #ccc',
                                borderRadius: '3px',
                              }}
                            >
                              <strong>{hoveredPolygon.name}</strong>
                              <br />
                              ID: {hoveredPolygon.id}
                            </div>
                          </OverlayView>
                        )}
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                {
                  <>
                    <Row className="mt-2">
                      {/* Zoom */}
                      <Col md={6}>
                        <FormGroup>
                          <label className="form-control-label">
                            Zoom para marketplace
                          </label>
                          <Controller
                            name="zoom"
                            control={control}
                            render={({ field }) => (
                              <Input
                                readOnly
                                className={errors.zoom?.message && 'is-invalid'}
                                {...field}
                                id="zoom"
                                type="number"
                              />
                            )}
                          />
                          <h5 className="text-danger">
                            {errors.zoom?.message}
                          </h5>
                        </FormGroup>
                      </Col>
                    </Row>

                    {updatePolygon && updatePolygon.polygon_id ? (
                      <Button
                        color={'default'}
                        onClick={handleDiscardPolygonChanges}
                      >
                        Descartar cambios
                      </Button>
                    ) : (
                      <>
                        <Button color={'default'} onClick={handleBuildPolygon}>
                          Visualizar poligono
                        </Button>
                        <Button color={'warning'} onClick={clearMap}>
                          Limpiar mapa
                        </Button>
                      </>
                    )}
                  </>
                }
              </CardFooter>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </AdminPage>
  );
};

export default AdminPolygons;
