export const DEFAULT_AVATAR =
  'https://storage.googleapis.com/assets-us-east4-propilatam-dev/avatars/default-avatar.png';

export const REALMS = {
  PROPER: 'proper',
  INTERNAL: 'internal',
};

export const CUSTOMERS = {
  investor: 'Inversionista',
  tenant: 'Inquilino',
  landlord: 'Propietario',
};

export const COLOR_CUSTOMER = {
  investor: 'primary',
  tenant: 'Inquilino',
  landlord: 'Propietario',
};

export type CustomerType = keyof typeof CUSTOMERS;
