import React, { useCallback, useEffect, useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getDocumentsCatalog,
  getProfileDocuments,
  sendDocumentsProfile,
} from './services/documentService';
import useAuth from '../../hooks/useAuth';
import useUserCountry from '../../hooks/useUserCountry';
import useAdminEntity from '../../hooks/useAdminEntity';
import {
  Catalog,
  CatalogDocument,
  ExistingDocument,
} from './models/DocumentData';
import LoadingCard from '../../components/LoadingCard';
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import InputMask from 'react-input-mask';
import { IconEye, IconTrash, IconUpload } from '@tabler/icons-react';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { ErrorAlert, SuccessAlert } from '../../models/alertModel';
import SweetAlert from 'react-bootstrap-sweetalert';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';

interface UploadedFile {
  file: File | null;
  id: string;
  text: string;
  isUploaded: boolean;
  document: string;
  code: string;
  input_mask: string;
  exists: boolean;
  error: string;
}

const DocumentsUser = () => {
  const { id: profileId = '' } = useParams();
  const [errorAlert, setErrorAlert] = useState(null);
  const { getCurrentCountry } = useUserCountry();
  const currentUserCountryCode = getCurrentCountry()?.code;
  const { callEndpoint } = useCallApiAndLoad();
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const [sweetAlert, setSweetAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<SuccessAlert | ErrorAlert>({
    success: true,
    title: '',
    text: '',
    onConfirm: () => setSweetAlert(false),
    onCancel: () => setSweetAlert(false),
  });

  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const pageName = 'Administrar documentos';
  const pageParentName = 'Detalle de usuario';

  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const getDocumentCatalog = useCallback(
    () => getDocumentsCatalog(currentUserCountryCode as string),
    [],
  );

  const getDocumentByProfile = useCallback(
    () => getProfileDocuments(profileId, token),
    [],
  );
  const [catalogDocument] = useAdminEntity<CatalogDocument>(
    getDocumentCatalog,
    setErrorAlert,
  );
  const [existsDocuments] = useAdminEntity<ExistingDocument[]>(
    getDocumentByProfile,
    setErrorAlert,
  );

  const handleTextChange = (text: string, id: string) => {
    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].text = text;
      setUploadedFiles(newFiles);
    }
  };

  const removeFile = (id: string) => {
    const newFiles = uploadedFiles.filter((file) => file.id !== id);
    setUploadedFiles(newFiles);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    event.stopPropagation();
    const newFiles = [...uploadedFiles];
    const file = event.target.files?.[0] || null;
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].file = file;
      setUploadedFiles(newFiles);
    }
  };

  useEffect(() => {
    if (catalogDocument?.documents?.length > 0) {
      const initialUploadedFiles: any = catalogDocument?.documents?.map(
        (document: Catalog) => ({
          file: null,
          id: document.id,
          text: '',
          isUploaded: false,
          document: document?.document,
          code: document?.code,
          input_mask: document?.input_mask,
          error: '',
        }),
      );
      setUploadedFiles(initialUploadedFiles);
    }
  }, [catalogDocument?.documents]);

  useEffect(() => {
    const combinedDocuments = catalogDocument?.documents?.map(
      (document: any) => {
        const existsDocument = existsDocuments?.find(
          (existsDocument: ExistingDocument) =>
            existsDocument.code === document.code,
        );

        if (existsDocument) {
          return { ...document, exists: true };
        } else {
          return { ...document, exists: false };
        }
      },
    );
    setUploadedFiles(combinedDocuments);
  }, [catalogDocument?.documents, existsDocuments]);

  const formDataToJson = (formData: any) => {
    return Object.fromEntries(formData.entries());
  };

  const submitFile = async (id: string, code: string) => {
    const uploadedFile = uploadedFiles.find((file) => file.id === id);
    if (!uploadedFile) return;
    const { file, text } = uploadedFile;
    const existsDocumentInList = existsDocuments.find(
      (doc: ExistingDocument) => doc.code === code,
    );
    console.log({ existsDocumentInList, text });
    let error = '';
    // Verificar si el documento ya existe antes de agregar document_input al formulario
    if (!existsDocumentInList && !text) {
      error = 'Debes ingresar un texto para el documento.';
    }

    // Actualizar el estado de error solo para este archivo
    const newFiles = [...uploadedFiles];
    const index = newFiles.findIndex((file) => file.id === id);
    if (index !== -1) {
      newFiles[index].error = error;
      setUploadedFiles(newFiles);
    }

    if (error) {
      return;
    }

    const formData = new FormData();
    formData.append('document_type', code);
    formData.append('profile_id', profileId);

    // Si el documento no existe o si se ha modificado el texto, agregar document_input al formulario
    if (!existsDocumentInList && text) {
      formData.append('document_input', text);
    } else if (existsDocumentInList) {
      formData.append('document_input', existsDocumentInList.document_number);
    }

    // Si el archivo está adjunto, agregarlo al formulario (campo opcional)
    if (file) {
      formData.append('document', file);
    }

    try {
      // Llamada a la función para crear o actualizar el documento
      await createDocument(formData);

      // Actualizar el estado para marcar el archivo como enviado
      const newFiles = [...uploadedFiles];
      const index = newFiles.findIndex((file) => file.id === id);
      if (index !== -1) {
        newFiles[index].isUploaded = true;
        setUploadedFiles(newFiles);
      }

      // Limpiar el error
      setError('');
    } catch (error) {
      // Manejar cualquier error que pueda ocurrir al enviar el documento
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Error al enviar el documento. Por favor, inténtalo de nuevo.',
      }));
      setSweetAlert(true);
    }
  };

  const createDocument = async (payload: any) => {
    const { status, data } = await callEndpoint(
      sendDocumentsProfile(token, payload),
    );
    const response = fetchResponseAdapter(data);
    if (status === 201) {
      setTimeout(() => {
        return navigate(0);
      }, 2000);
    } else {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: response.message,
      }));
      setSweetAlert(true);
      document.body.style.overflowY = 'scroll';
    }
  };

  if (!catalogDocument)
    return <LoadingCard name={pageName} parent_name={pageParentName} />;

  return (
    <AdminPage name={pageName} parentName={pageParentName}>
      {sweetAlert && (
        <SweetAlert {...alertProps}>{alertProps.text || ''}</SweetAlert>
      )}
      <h1 className=" mb-3">Documentación de usuarios</h1>
      <Form>
        <Row xs="3" className="">
          {uploadedFiles?.map(
            ({
              file,
              id,
              text,
              isUploaded,
              document,
              code,
              input_mask,
              exists,
              error,
            }) => (
              <Col key={id} className="p-4">
                <h3>{document}</h3>
                <FormGroup>
                  <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    accept=".pdf,image/*"
                    onChange={(e) => handleFileChange(e, id)}
                  />
                </FormGroup>
                {exists ? (
                  <FormGroup>
                    <p className="font-weight-bold mb-0">Numero de documento</p>
                    {existsDocuments?.map((doc: ExistingDocument) => {
                      if (doc?.code === code) {
                        const documentNumber = doc?.document_number || '';

                        return (
                          <InputMask
                            key={doc.id}
                            className="w-100 focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                            mask={input_mask}
                            alwaysShowMask={false}
                            onChange={(e) =>
                              handleTextChange(e.target.value, id)
                            }
                            defaultValue={documentNumber}
                          />
                        );
                      }
                    })}
                  </FormGroup>
                ) : (
                  <div className="mb-4">
                    <p className="mb-0 font-weight-bold">Numero de documento</p>

                    <InputMask
                      className="w-100 focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                      mask={input_mask}
                      alwaysShowMask={false}
                      onChange={(e) => handleTextChange(e.target.value, id)}
                    />
                  </div>
                )}

                {/* Preview of document to upload */}
                {file && (
                  <>
                    <p className="font-weight-bold text-[#212121] mb-3">
                      Documentos por subir
                    </p>
                    <div className="d-flex justify-content-between items-center mt-2">
                      <p className="text-[#757575] text-sm font-medium">
                        {file.name} -
                      </p>
                      <button
                        style={{ background: 'none', border: 'none' }}
                        onClick={() => removeFile(id)}
                      >
                        <IconTrash color="#F44336" />
                      </button>
                    </div>
                  </>
                )}

                {/* Preview of existing uploaded document */}
                {exists && (
                  <>
                    <p className="font-weight-bold text-[#212121] mb-2">
                      Documentos subidos
                    </p>
                    {existsDocuments.map((doc: ExistingDocument) => {
                      if (doc.code === code) {
                        return doc.document_path ? (
                          <a /** Documento subido previamente */
                            href={doc?.document_path}
                            target="_blank"
                            key={doc.id}
                            className="text-sm text-[#757575] flex justify-start items-center gap-2"
                            rel="noreferrer"
                          >
                            <IconEye color="#757575" /> {doc.document}
                          </a>
                        ) : (
                          /** Documento aún no ha sido subido */
                          <span
                            key={`not-found-${doc.id}`}
                            className="text-sm text-[#757575] flex justify-start items-center gap-2"
                          >
                            Archivo pendiente.
                          </span>
                        );
                      }
                    })}
                  </>
                )}

                <div>
                  <Button
                    className="w-100 mt-3"
                    onClick={() => submitFile(id, code)}
                    color="primary"
                  >
                    <IconUpload color="#fff" />
                    {/* {isUploaded ? "Enviado" : "Subir documento"} */}
                    {exists ? 'Actualizar documento' : 'Subir documento'}
                  </Button>
                </div>
                {error && (
                  <p className="mt-2" style={{ color: '#F44336' }}>
                    {error}
                  </p>
                )}
              </Col>
            ),
          )}
        </Row>
      </Form>
    </AdminPage>
  );
};

export default DocumentsUser;
