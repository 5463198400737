import { useState } from 'react';
import AdminPage from '../../components/admin/AdminPage';
import AdminPaginator from '../../components/admin/AdminPaginator';
import useAdminPagination from '../../hooks/useAdminPagination';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { Customer } from './models/customerModel';
import { customerListEndpoint } from './services/customerService';
import GridSearchBar from '../../components/searchBar/GridSearchBar';
import useAuth from '../../hooks/useAuth';
import {
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import ProfileAvatarGroup from '../../components/ProfileAvatarGroup';
import {
  getLandlordByEmailEndpoint,
  getLandlordByNameEndpoint,
} from '../accounting/services/accountingService';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { CUSTOMERS, CustomerType } from '../../constants/profileConstants';
import { requestChangePasswordEndpoint } from '../../services/publicService';
import { ErrorAlert, SuccessAlert } from '../../models/alertModel';

const Customers = () => {
  const [sweetAlert, setSweetAlert] = useState<boolean>(false);
  const [alertProps, setAlertProps] = useState<SuccessAlert | ErrorAlert>({
    success: true,
    title: '',
    text: '',
    onConfirm: () => setSweetAlert(false),
    onCancel: () => setSweetAlert(false),
  });
  const { getAuthToken } = useAuth();
  const token = getAuthToken();

  const { callEndpoint, isLoading } = useCallApiAndLoad();
  const [errorAlert, setErrorAlert] = useState(null);
  const [customers, setCustomers] = useState<Customer[]>([]);

  const { paginator, loadItems } = useAdminPagination(
    customerListEndpoint,
    setCustomers,
    setErrorAlert,
  );

  const colorPerTag = (tag: CustomerType): string => {
    let color: string = '';

    if (tag === 'investor') color = 'primary';
    if (tag === 'landlord') color = 'info';
    if (tag === 'tenant') color = 'success';

    return color;
  };

  const sendChangePassword = async (email: string) => {
    const { status } = await callEndpoint(requestChangePasswordEndpoint(email));
    if (status === 200) {
      setAlertProps((state) => ({
        ...state,
        success: true,
        danger: false,
        title: 'OK',
        text: 'Correo enviado satisfactoriamente',
      }));
    } else {
      setAlertProps((state) => ({
        ...state,
        success: false,
        danger: true,
        title: 'Error',
        text: 'Lo sentimos ocurrió un error al querer mandar correo de cambio de contraseña',
      }));
    }
    setSweetAlert(true);
  };

  const getCustomerPill = (tags: CustomerType[]) => {
    if (tags.length === 0) {
      return (
        <Badge color="default" pill>
          Pendiente
        </Badge>
      );
    }
    return (
      <>
        {tags.map((tag, index) => (
          <Badge key={index} color={colorPerTag(tag)} pill>
            {CUSTOMERS[tag]}
          </Badge>
        ))}
      </>
    );
  };

  const usersFilters = [
    {
      label: 'Nombre usuario',
      value: 'landlord_name',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el nombre del usuario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByNameEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">
                  {landlord.phone} {landlord.email}
                </small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
    {
      label: 'Email usuario',
      value: 'landlord_email',
      apiParamName: 'landlord_id',
      inputType: 'search',
      isUnique: true,
      placeholder: 'Ingrese el email del usuario',
      inputTypeChoices: async (searchTerm: string) => {
        if (!searchTerm) return [];

        const { status, data } = await callEndpoint(
          getLandlordByEmailEndpoint(searchTerm, token),
        );

        if (status === 200) {
          const response = fetchResponseAdapter(data);
          return response.data.map((landlord: any) => ({
            value: landlord.landlord_id,
            label: (
              <>
                <span>{landlord.name}</span>
                <small className="text-gray mx-2">
                  {landlord.phone} {landlord.email}
                </small>
              </>
            ),
          }));
        } else {
          return [];
        }
      },
    },
  ];

  const componentHeader = <GridSearchBar filtersList={usersFilters} />;

  const componentPagination = (
    <AdminPaginator
      page={paginator.page}
      totalPages={paginator.pageCount}
      onPageChange={loadItems}
    />
  );

  const componentTable = (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Avatar</th>
          <th scope="col">Nombre</th>
          <th scope="col">Apellido</th>
          <th scope="col">Correo</th>
          <th scope="col">Teléfono</th>
          <th scope="col">Tipo</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody>
        {customers.length > 0 &&
          customers.map((customer, key) => (
            <tr key={customer.id}>
              <td>
                <ProfileAvatarGroup
                  code={key}
                  name={customer.name + ' ' + customer.lastname}
                  avatar={customer.avatar ? customer.avatar : ''}
                />
              </td>
              <td>{customer.name}</td>
              <td>{customer.lastname}</td>
              <td>{customer.email}</td>
              <td>{customer.phone}</td>
              <td>{getCustomerPill(customer.tags)}</td>
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href={`/customer/${customer.landlord_id}/profile`}
                    >
                      Ver perfil
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        sendChangePassword(customer.email);
                      }}
                    >
                      Envíar cambio de contraseña
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );

  return (
    <AdminPage
      name="Lista de usuarios"
      parentName="Usuarios"
      footer={componentPagination}
      header={componentHeader}
    >
      {componentTable}
    </AdminPage>
  );
};

export default Customers;
