import axios from 'axios';

const loadAbort = () => {
  return new AbortController();
};

export const getOfferDetailEndpoint = (offerId: string = '', token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/ts/v1/admin/properties/offers/${offerId}/detail`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getCountriesEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/countries`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getStatesEndpoint = (countryId: string, token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/countries/${countryId}/states`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getCitiesEndpoint = (
  countryId: string,
  stateId: string,
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/countries/${countryId}/states/${stateId}/cities`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getProperByMailEndpoint = (email: string, token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/proper/search-mail`,
        params: { email },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getPropersSelectList = (
  userCountryCode: string,
  properBussinessAssigned: string = 'all',
  token: string = '',
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/user/v1/admin/proper/list?country=${userCountryCode}&properBussinessAssigned=${properBussinessAssigned}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const requestChangePasswordEndpoint = (
  email: string,
  token: string = '',
) => {
  const controller = loadAbort();
  // TODO FIX PATH
  return {
    call: (urlService: string) =>
      axios({
        method: 'post',
        url: `${urlService}/user/v1/auth/user/request-change-password`,
        data: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getStatesForSearchEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/countries/cdc08450-28b6-4cc9-9142-4358ef01a3cc/states`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getCitiesForSearchEndpoint = (token: string = '') => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/cities`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getCurrenciesPerCountry = (countryId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: 'get',
        url: `${urlService}/rs/v1/country-state-cities/countries/${countryId}/currencies`,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    controller,
  };
};
